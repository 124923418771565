import { ClientModel } from "..";
class AbstractPost extends ClientModel {
    transformDataForPersist() {
        var _data_title, _data_title1, _data_content, _data_content1;
        const data = {
            ...super.transformDataForPersist()
        };
        data.title = typeof ((_data_title = data.title) == null ? void 0 : _data_title.raw) === "string" ? data.title.raw : (_data_title1 = data.title) == null ? void 0 : _data_title1.rendered;
        data.content = typeof ((_data_content = data.content) == null ? void 0 : _data_content.raw) === "string" ? data.content.raw : (_data_content1 = data.content) == null ? void 0 : _data_content1.rendered;
        delete data._links;
        delete data.link;
        return data;
    }
    transformDataForPatch() {
        return this.transformDataForPersist();
    }
}
export { AbstractPost };
